import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { DashboardBlockKind } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import { AddDashboardBlockFormState } from "@/dashboard/add/AddDashboardBlockModal"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import {
  DashboardBlockPosition,
  EditDashboardBlockFormFragment$data,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  EditDashboardBlockFormMutation,
  EditDashboardBlockInput,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import BannerDashboardBlockForm from "@/dashboard/form/BannerDashboardBlockForm"
import ChannelsDashboardBlockForm from "@/dashboard/form/ChannelsDashboardBlockForm"
import CollectionFolderDashboardBlockForm from "@/dashboard/form/CollectionFolderDashboardBlockForm"
import CommunityWelcomeHeroDashboardBlockForm from "@/dashboard/form/CommunityWelcomeHeroDashboardBlockForm"
import ContentDashboardBlockForm from "@/dashboard/form/ContentDashboardBlockForm"
import ContinueYourProductsDashboardBlockForm from "@/dashboard/form/ContinueYourProductsDashboardBlockForm"
import CurriculumDashboardBlockForm from "@/dashboard/form/CurriculumDashboardBlockForm"
import EventsDashboardBlockForm from "@/dashboard/form/EventsDashboardBlockForm"
import ExperienceDetailsDashboardBlockForm from "@/dashboard/form/ExperienceDetailsDashboardBlockForm"
import FeaturedItemsDashboardBlockForm from "@/dashboard/form/FeaturedItemsDashboardBlockForm"
import FeedDashboardBlockForm from "@/dashboard/form/FeedDashboardBlockForm"
import HeroDashboardBlockForm from "@/dashboard/form/HeroDashboardBlockForm"
import LeaderboardDashboardBlockForm from "@/dashboard/form/LeaderboardDashboardBlockForm"
import MembersListDashboardBlockForm from "@/dashboard/form/MembersListDashboardBlockForm"
import RichTextDashboardBlockForm from "@/dashboard/form/RichTextDashboardBlockForm"
import WelcomeBannerDashboardBlockForm from "@/dashboard/form/WelcomeBannerDashboardBlockForm"
import { useMemo } from "react"

export type DashboardBlockFormStore =
  | FormStore<AddDashboardBlockFormState>
  | FormStore<
      EditDashboardBlockInput & Partial<EditDashboardBlockFormFragment$data>,
      EditDashboardBlockFormMutation
    >

export interface BlockFormConfig {
  form: React.FC<{ form: DashboardBlockFormStore; onClose: () => void }>
  name: string
  description: string
  alert?: string
}
type BlockKind = Exclude<DashboardBlockKind, "%future added value">
type BlockKindConfigs = Partial<Record<BlockKind, BlockFormConfig>>

export function useDashboardBlockKindForms(
  position?: DashboardBlockPosition
): BlockKindConfigs {
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const dashboard = useDashboardContext()!
  const singleColumnExperienceDashboard =
    activeProduct?.dashboard?.layout === "one_column"

  const baseConfig = useMemo<BlockKindConfigs>(
    // The order here determines the display order in DashboardBlockKindsGrid
    () => ({
      community_welcome_hero: {
        form: CommunityWelcomeHeroDashboardBlockForm,
        name: "Hero",
        description: "Set a cover image and text to welcome to your community.",
      },
      banner: {
        form: BannerDashboardBlockForm,
        name: `${labels.admin_experience.singular} Banner`,
        description: `Set an image as ${labels.admin_experience.singular} banner and provide high-level ${labels.admin_experience.singular} details.`,
      },
      experience_details: {
        form: ExperienceDetailsDashboardBlockForm,
        name: `${labels.admin_experience.singular} Details`,
        description: `Provide general details about the ${labels.admin_experience.singular}`,
      },
      hero: {
        form: HeroDashboardBlockForm,
        name: "Hero Banner Block",
        description:
          "A hero banner with a custom background (image or solid color) and a welcome message option. Only one Hero Banner block may be added.",
        alert: "The Hero Banner block will be added to the top of the page.",
      },
      continue_your_products: {
        form: ContinueYourProductsDashboardBlockForm,
        name: "Jump Back In Block",
        description: `Displays ${labels.admin_experience.plural} that ${labels.organization_member.plural} were recently in, letting them jump back in where they left off. Only one Jump Back In block may be added.`,
      },
      recently_viewed: {
        form: () => null,
        name: "Recently Viewed Block",
        description: `Displays the content or events ${labels.organization_member.plural} have viewed in the last 7 days. Only one Recently Viewed block may be added.`,
        alert: "Only one Recently Viewed block may be added",
      },
      feed: {
        form: FeedDashboardBlockForm,
        name: "Posts Block",
        description: `Aggregates posts from across all Feeds the ${labels.organization_member.singular} has access to, displayed from newest to oldest. Only one Posts block may be added.`,
        alert: `Only one Feed block can be added. This block will aggregate all feeds across this ${
          activeProduct ? labels.experience.singular : "community"
        }.`,
      },
      upcoming_events: {
        form: EventsDashboardBlockForm,
        name: "Upcoming Events Block",
        description:
          "Displays the latest and upcoming events to boost attendance. Only one Events block may be added.",
      },
      welcome_banner: {
        form: WelcomeBannerDashboardBlockForm,
        name: "Community Details Block",
        description: `Displays a community overview featuring a custom message, title, subtitle, and an accompanying image.${
          dashboard.isOneColumn ? " This block is not ideal for the 1-column layout." : ""
        }`,
      },
      members_list: {
        form: MembersListDashboardBlockForm,
        name: `${labels.admin_member.plural} Block`,
        description: "Displays the member directory to encourage social interactions.",
      },
      leaderboard: {
        form: LeaderboardDashboardBlockForm,
        name: "Leaderboard Block",
        description: `Show a leaderboard based on ${labels.admin_member.plural} engagement score in your selected time period.`,
      },
      rich_text: {
        form: RichTextDashboardBlockForm,
        name: "Rich Text Block",
        description:
          "Use the rich text editor to create a custom block that allows you to add different types of content (text, images, videos, attachments, and embeds).",
      },
      featured_items: {
        form: FeaturedItemsDashboardBlockForm,
        name: `Featured ${labels.admin_experience.plural}`,
        description: `Display published ${labels.admin_experience.plural} to drive engagement and registration.`,
      },
      memberships: {
        form: FeaturedItemsDashboardBlockForm,
        name: "Featured Memberships",
        description:
          "Spotlight Memberships from your community to drive engagement and registration.",
      },
      content: {
        form: ContentDashboardBlockForm,
        name: "Content Block",
        description:
          "Displays a selected content from across your community (lesson, quiz, assignment, survey, etc.)",
      },
      collection_folder: {
        form: CollectionFolderDashboardBlockForm,
        name: "Collection Folder Block",
        description:
          "Displays a selected collection from within Products or collections apps.",
      },
      curriculum: {
        form: CurriculumDashboardBlockForm,
        name: labels.curriculum.singular,
        description: "Show incomplete and upcoming Modules to drive engagement.",
      },
      channels: {
        form: ChannelsDashboardBlockForm,
        name: "Channels",
        description: `Show discussions across the ${labels.admin_experience.singular} channels to drive engagement.`,
      },
    }),
    [activeProduct, labels, dashboard.isOneColumn]
  )

  const allowedKinds = getAllowedBlockKinds()
  return Object.entries(baseConfig).reduce<BlockKindConfigs>((conf, [k, kindConf]) => {
    const kind = k as BlockKind
    if (allowedKinds.has(kind)) conf[kind] = kindConf
    return conf
  }, {})

  function getAllowedBlockKinds(): Set<BlockKind> {
    // Public community landing page
    if (dashboard.isCommunityWelcome) {
      return new Set([
        "community_welcome_hero",
        "rich_text",
        "featured_items",
        "memberships",
      ])
    }

    // Apps common to both product and community dashboards
    const blocks = new Set<BlockKind>([
      "members_list",
      "upcoming_events",
      "leaderboard",
      "rich_text",
      "featured_items",
      "memberships",
      "content",
      "collection_folder",
    ])
    if (position === "main") blocks.add("feed")

    // Product dashboard
    if (activeProduct) {
      blocks.add(singleColumnExperienceDashboard ? "banner" : "experience_details")
      blocks.add("curriculum")
      if (position === "main") blocks.add("channels")
      return blocks
    }

    // Community dashboard
    blocks.add("welcome_banner")
    blocks.add("continue_your_products")
    blocks.add("recently_viewed")
    if (dashboard.isOneColumn) blocks.add("hero")
    return blocks
  }
}
