import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { AddDashboardBlockFormState } from "@/dashboard/add/AddDashboardBlockModal"
import {
  DashboardBlockContentMode,
  DashboardBlockHeroMode,
  DashboardBlockLeaderboardLookbackWindow,
} from "@/dashboard/add/__generated__/AddDashboardBlockModalMutation.graphql"
import { DashboardBlockKindsGridQuery } from "@/dashboard/add/__generated__/DashboardBlockKindsGridQuery.graphql"
import { DashboardBlockKind } from "@/dashboard/blocks/__generated__/DashboardBlockItemFragment.graphql"
import {
  BlockFormConfig,
  useDashboardBlockKindForms,
} from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockEventsView } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockFragment.graphql"
import { DashboardBlockFeedView } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockFragment.graphql"
import {
  DashboardBlockCurriculumView,
  DashboardBlockLeaderboardView,
  DashboardBlockPosition,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  DashboardBlockFeaturedItemConfigKind,
  DashboardBlockHeroHeaderText,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import WhiteCheckMarkIcon from "@assets/disco/icons/gradient/gradient-white-check-mark.svg"
import WhiteCollectionFolderIcon from "@assets/disco/icons/gradient/gradient-white-collection-folder.svg"
import WhiteCommunityDetailsIcon from "@assets/disco/icons/gradient/gradient-white-community-details.svg"
import WhiteContentIcon from "@assets/disco/icons/gradient/gradient-white-content.svg"
import WhiteFeaturedItemsIcon from "@assets/disco/icons/gradient/gradient-white-featured-items.svg"
import WhiteFeaturedProductsIcon from "@assets/disco/icons/gradient/gradient-white-featured-products.svg"
import WhiteLightBulbIcon from "@assets/disco/icons/gradient/gradient-white-light-bulb.svg"
import WhiteMembersIcon from "@assets/disco/icons/gradient/gradient-white-members.svg"
import WhitePostsIcon from "@assets/disco/icons/gradient/gradient-white-posts.svg"
import WhiteRichTextIcon from "@assets/disco/icons/gradient/gradient-white-rich-text.svg"
import WhiteLeaderboardIcon from "@assets/disco/icons/gradient/gradient-white-trophy.svg"
import WhiteUpcomingEventsIcon from "@assets/disco/icons/gradient/gradient-white-upcoming-events.svg"
import ExperienceDetails from "@assets/images/covers/dashboard-blocks/experience_details.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoIcon, DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import DiscoTemplateCardButton from "@disco-ui/button/DiscoTemplateCardButton"
import { DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { range } from "lodash"
import { action, observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useDashboardContext } from "../context/DashboardContext"

type BlockKind = Exclude<DashboardBlockKind, "%future added value">

export type DashboardBlockGridKinds = Partial<
  Record<Exclude<DashboardBlockKind, "%future added value">, BlockFormConfig>
>

const PERSONALIZED_BLOCKS: DashboardBlockKind[] = [
  "recently_viewed",
  "continue_your_products",
  "hero",
  "feed",
  "upcoming_events",
]

const ICONS: Record<DashboardBlockKind, any> = {
  hero: <WhiteCommunityDetailsIcon />,
  leaderboard: <WhiteLeaderboardIcon />,
  welcome_banner: <WhiteCommunityDetailsIcon />,
  feed: <WhitePostsIcon />,
  members_list: <WhiteMembersIcon />,
  featured_items: <WhiteFeaturedProductsIcon />,
  memberships: <WhiteFeaturedItemsIcon />,
  rich_text: <WhiteRichTextIcon />,
  content: <WhiteContentIcon />,
  experience_details: <ExperienceDetails />,
  upcoming_events: <WhiteUpcomingEventsIcon />,
  curriculum: <WhiteCheckMarkIcon />,
  channels: <WhiteLightBulbIcon />,
  banner: <WhiteCommunityDetailsIcon />,
  community_welcome_hero: null,
  collection_folder: <WhiteCollectionFolderIcon />,
  product_admin: null,
  pathway_sequence: null,
  continue_your_products: <WhiteLightBulbIcon />,
  recently_viewed: <WhiteCheckMarkIcon />,
  "%future added value": null,
}

interface Props {
  form: FormStore<AddDashboardBlockFormState>
  position: DashboardBlockPosition
  dashboardId: GlobalID
}

function DashboardBlockKindsGrid(props: Props) {
  const { form, position, dashboardId } = props
  const classes = useStyles()
  const kinds = useDashboardBlockKindForms(position)
  const { connectedApps: connectedProductApps } = useConnectedProductApps()
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const labels = useLabels()
  const publicAvailabilityLabel = ProductUtils.getAvailabilityLabel(
    "public",
    labels.organization_member
  )
  const privateAvailabilityLabel = ProductUtils.getAvailabilityLabel(
    "private",
    labels.organization_member
  )
  const { isForYou } = useDashboardContext()!

  const { dashboard } = useLazyLoadQuery<DashboardBlockKindsGridQuery>(
    graphql`
      query DashboardBlockKindsGridQuery(
        $dashboardId: ID!
        $productAppKind: ProductAppKind!
      ) {
        dashboard: node(id: $dashboardId) {
          ... on Dashboard {
            mainBlocks: blocks(position: main) {
              edges {
                node {
                  id
                  kind
                }
              }
            }
            landingPageId
            organization {
              apps(kind: $productAppKind, includeNested: true) {
                totalCount
              }
              products(hideNonPublic: true, type: "course") {
                edges {
                  node {
                    id
                    registrationAvailability
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      dashboardId,
      productAppKind: "collection",
    },
    { fetchPolicy: "network-only" }
  )

  const mainBlocks = Relay.connectionToArray(dashboard?.mainBlocks)
  const blockKindsSet = new Set(mainBlocks.map((b) => b.kind))
  const isCommunityWelcome = Boolean(dashboard?.landingPageId)
  const allProducts = Relay.connectionToArray(dashboard?.organization?.products)

  const personalizedBlocks: DashboardBlockGridKinds = {}
  const standardBlocks: DashboardBlockGridKinds = {}

  Object.entries(kinds).forEach(([kind, config]) => {
    if (PERSONALIZED_BLOCKS.includes(kind as DashboardBlockKind)) {
      personalizedBlocks[kind as BlockKind] = config
    } else {
      standardBlocks[kind as BlockKind] = config
    }
  })

  return (
    <div className={classes.list}>
      {renderSection(
        personalizedBlocks,
        "Personalized Blocks",
        `Blocks that provide ${labels.organization_member.plural} with a personalized experience. The content displayed in these blocks is tailored to each ${labels.organization_member.singular}.`
      )}

      {renderSection(
        standardBlocks,
        "Standard Blocks",
        `The content displayed within these blocks is standardized for all ${labels.organization_member.plural}, ensuring that everyone has access to the same information and resources.`
      )}
    </div>
  )

  function renderSection(
    blocks: DashboardBlockGridKinds,
    title: string,
    tooltip: string
  ) {
    const blocksList = Object.entries(blocks)
    if (!blocksList.length) return
    return (
      <>
        <div className={classes.title}>
          <DiscoText variant={"body-xs-500-uppercase"} color={"text.secondary"}>
            {title}
          </DiscoText>

          <DiscoTooltip content={tooltip}>
            <div className={classes.icon}>
              <DiscoIcon icon={"info"} width={20} height={20} />
            </div>
          </DiscoTooltip>
        </div>
        <div className={classes.root}>
          {blocksList.map(([kind, config]) => {
            return (
              <DiscoTemplateCardButton
                key={kind}
                testid={`DashboardBlockKindsGrid.${kind}`}
                title={config.name}
                description={config.description}
                disabled={getBlockAttributes(kind as DashboardBlockKind)?.disabled}
                tooltip={getBlockAttributes(kind as DashboardBlockKind)?.tooltip}
                onClick={action(getOnClick(kind as DashboardBlockKind))}
                svg={ICONS[kind as DashboardBlockKind]}
              />
            )
          })}
        </div>
      </>
    )
  }

  function getOnClick(kind: DashboardBlockKind) {
    switch (kind) {
      case "hero":
        return () => {
          form.state.kind = "hero"
          form.state.hero = observable({
            header: "default_hello" as DashboardBlockHeroHeaderText,
            subText: "",
            mode: "color" as DashboardBlockHeroMode,
            color: "#F7F0E7",
            showWelcomeText: true,
            askAi: true,
            textColor: "#000000",
          })
          form.state.showOnMobile = true
        }
      case "welcome_banner":
        return () => {
          form.state.kind = "welcome_banner"
          form.state.welcomeBanner = observable({
            title: `About ${activeOrganization.name}`,
            subtitle: `Welcome to ${activeOrganization.name}`,
            richEditorDescription: JSON.stringify(
              EditorUtils.createParagraphs([
                activeOrganization.description ||
                  `A brief description of your community goes here to welcome your ${labels.admin_member.plural}! You can click the icon above to edit this.`,
              ])
            ),
            cover: activeOrganization?.cover,
          })
        }
      case "rich_text":
        return () => {
          form.state.kind = "rich_text"
          form.state.richText = observable({
            title: null,
            ctaText: null,
            ctaUrl: null,
            richEditorContent: JSON.stringify(EditorUtils.createParagraphs([""])),
            hasCtaButton: false,
          })
        }
      case "content":
        return () => {
          form.state.kind = "content"
          form.state.content = observable({
            contentId: "",
            mode: "preview" as DashboardBlockContentMode,
          })
        }
      case "collection_folder":
        return () => {
          form.state.kind = "collection_folder"
          form.state.collectionFolder = observable({
            contentId: "",
          })
        }
      case "experience_details":
        return () => {
          form.state.kind = "experience_details"
          form.state.experienceDetails = observable({
            description: activeProduct?.description ?? "",
            showMembers: Boolean(activeProduct?.isMembersAppVisibleToAll),
            showDates: Boolean(activeProduct?.startDate),
            showCountdown: Boolean(activeProduct?.startDate),
            showProgress: Boolean(connectedProductApps.has("curriculum")),
            showAttendance: Boolean(connectedProductApps.has("events")),
          })
        }
      case "featured_items":
        return () => {
          form.state.kind = "featured_items"
          form.state.featuredItems = observable({
            featuredProductIds: observable.array([]),
            featuredKind: "experience" as DashboardBlockFeaturedItemConfigKind,
            showCountdown: false,
          })
        }
      case "memberships":
        return () => {
          // Note: Using "featured_items" for kind is intentional.
          // Memberships are a type of featured items, distinguished by featuredKind.
          form.state.kind = "featured_items"
          form.state.featuredItems = observable({
            featuredProductIds: observable.array([]),
            featuredKind: "membership" as DashboardBlockFeaturedItemConfigKind,
            showCountdown: false,
          })
        }
      case "upcoming_events":
        return () => {
          form.state.kind = "upcoming_events"
          form.state.upcomingEvents = observable({
            count: 3,
            eventType: "community_and_experience",
            view: (isForYou || activeProduct
              ? "card"
              : "list") as DashboardBlockEventsView,
            showCover: true,
          })
        }
      case "members_list":
        return () => {
          form.state.kind = "members_list"
          form.state.membersList = observable({
            count: 4,
            memberType: "all" as const,
          })
        }
      case "leaderboard":
        return () => {
          form.state.kind = "leaderboard"
          form.state.leaderboard = observable({
            lookbackWindow: "last_90_days" as DashboardBlockLeaderboardLookbackWindow,
            count: 10,
            title: "Leaderboard",
            description: "The top members based on their activity.",
            view: (activeProduct || isForYou
              ? "card"
              : "list") as DashboardBlockLeaderboardView,
          })
        }
      case "feed":
        return () => {
          form.state.kind = "feed"
          form.state.feed = observable({
            title: "Posts",
            view: (isForYou || activeProduct ? "card" : "list") as DashboardBlockFeedView,
          })
        }
      case "curriculum":
        return () => {
          form.state.kind = "curriculum"
          form.state.curriculum = observable({
            title: labels.curriculum.singular,
            view: "card" as DashboardBlockCurriculumView,
          })
        }
      case "channels":
        return () => {
          form.state.kind = "channels"
          form.state.channels = observable({
            title: "Channels",
          })
        }
      case "banner":
        return () => {
          form.state.kind = "banner"
          form.state.banner = observable({
            showDates: Boolean(activeProduct?.startDate),
            showCountdown: Boolean(activeProduct?.startDate),
            showCover: Boolean(activeProduct?.cover),
            showAvailability: true,
            cover: activeProduct?.cover,
          })
        }
      case "community_welcome_hero":
        return () => {
          form.state.kind = "community_welcome_hero"
          form.state.communityWelcomeHero = observable({
            title: `Welcome to ${activeOrganization.name}`,
            subtitle: activeOrganization.description || "",
            cover: null,
          })
        }
      case "continue_your_products":
        return () => {
          form.state.kind = "continue_your_products"
          form.state.continueYourProducts = observable({
            title: "Jump Back In",
          })
        }
      case "recently_viewed":
      default:
        return () => (form.state.kind = kind as DashboardBlockKind)
    }
  }

  /** Determine the tooltip and if the block is disabled on the grid */
  function getBlockAttributes(kind: DashboardBlockKind) {
    switch (kind) {
      case "hero": {
        const hasHero = blockKindsSet.has("hero")
        return {
          disabled: hasHero,
          tooltip: "Only one Hero block may be added",
        }
      }
      // Disabled when the feed app is not connected or a Feed block already exists
      case "upcoming_events": {
        const hasEventsBlock = blockKindsSet.has("upcoming_events")
        const noProductApp = activeProduct && !connectedProductApps.has("events")
        const disabled = noProductApp || hasEventsBlock

        const eventsDisabledMessage = hasEventsBlock
          ? "Only one Events block may be added"
          : `${labels.admin_experience.singular} must have a Events app enabled`

        return {
          disabled,
          tooltip: disabled ? eventsDisabledMessage : "",
        }
      }
      // Disabled when the feed app is not connected or a Feed block already exists
      case "feed": {
        const hasFeedBlock = blockKindsSet.has("feed")
        const noProductApp = activeProduct && !connectedProductApps.has("posts")
        const disabled = noProductApp || hasFeedBlock

        const feedDisabledMessage = hasFeedBlock
          ? "Only one Feed block may be added"
          : `${labels.admin_experience.singular} must have a Feed app enabled`

        return {
          disabled,
          tooltip: disabled ? feedDisabledMessage : "",
        }
      }
      // Disabled when there are no public/private products in the organization
      case "featured_items": {
        const featurableAvailabilities = ["public"]
        if (!isCommunityWelcome) featurableAvailabilities.push("private")
        const featurableProducts = allProducts.filter((p) =>
          featurableAvailabilities.includes(p.registrationAvailability)
        )
        const disabled = !featurableProducts.length
        return {
          disabled,
          tooltip: disabled
            ? `Community must have at least one ${publicAvailabilityLabel} ${
                isCommunityWelcome ? "" : `or ${privateAvailabilityLabel} `
              }${labels.admin_experience.singular}.`
            : "",
        }
      }
      case "collection_folder": {
        if (activeProduct) {
          const disabled = !connectedProductApps?.has("collection")
          return {
            disabled,
            tooltip: disabled
              ? `${labels.admin_experience.singular} must have a collection`
              : "",
          }
        }
        const disabled = !dashboard?.organization?.apps?.totalCount
        return {
          disabled,
          tooltip: disabled ? "Community must have a collection" : "",
        }
      }
      // Disabled when the curriculum app is not connected or a Feed block already exists
      case "curriculum": {
        const hasCurriculumBlock = blockKindsSet.has("curriculum")
        const disabled = !connectedProductApps.has("curriculum") || hasCurriculumBlock
        const curriculumDisabledMessage = hasCurriculumBlock
          ? `Only one ${labels.curriculum.singular} block may be added`
          : `${labels.admin_experience.singular} must have the ${labels.curriculum.singular} app enabled`

        return {
          disabled,
          tooltip: disabled ? curriculumDisabledMessage : "",
        }
      }

      // Disabled when there is already a Channels block added
      case "channels": {
        const hasChannelsBlock = blockKindsSet.has("channels")

        const channelsDisabledMessage = hasChannelsBlock
          ? "Only one Channels block may be added"
          : `${labels.admin_experience.singular} must have a Channels app enabled`

        const disabled = !connectedProductApps.has("chat_channel") || hasChannelsBlock
        return {
          disabled,
          tooltip: disabled ? channelsDisabledMessage : "",
        }
      }
      // Disabled when Banner block already exists
      case "banner": {
        const disabled = blockKindsSet.has("banner")
        return {
          disabled,
          tooltip: disabled ? "Only one Banner block may be added" : "",
        }
      }
      // Disabled when Community Welcome Hero block already exists
      case "community_welcome_hero": {
        const disabled = blockKindsSet.has("community_welcome_hero")
        return {
          disabled,
          tooltip: disabled ? "Only one Hero Banner block may be added" : "",
        }
      }
      // Disable Continue Your Products block if already exists
      case "continue_your_products": {
        const disabled = blockKindsSet.has("continue_your_products")
        return {
          disabled,
          tooltip: `Only one Jump Back In block may be added`,
        }
      }
      // Disable Recently Viewed block if already exists
      case "recently_viewed": {
        const disabled = blockKindsSet.has("recently_viewed")
        return {
          disabled,
          tooltip: "Only one Recently Viewed block may be added",
        }
      }
      default:
        return { disabled: false, tooltip: "" }
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, minMax(0, 1fr))",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    gap: theme.spacing(2),
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  icon: {
    height: "20px",
  },
  skeletonCard: {
    padding: theme.spacing(2),
    height: "100px",
  },
  skeletonCardButton: {
    display: "none",
  },
}))

function DashboardBlockKindsGridSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.list}>
      <DiscoTextSkeleton width={100} />
      <div className={classes.root}>
        {range(2).map((i) => (
          <DiscoCardSkeleton
            key={i}
            variant={"compact"}
            classes={{
              card: classes.skeletonCard,
              buttons: classes.skeletonCardButton,
            }}
          />
        ))}
      </div>

      <DiscoTextSkeleton width={100} />
      <div className={classes.root}>
        {range(12).map((i) => (
          <DiscoCardSkeleton
            key={i}
            variant={"compact"}
            classes={{
              card: classes.skeletonCard,
              buttons: classes.skeletonCardButton,
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(DashboardBlockKindsGrid),
  skeleton: DashboardBlockKindsGridSkeleton,
})
